import { render, staticRenderFns } from "./MoveFilesToCategoryModal.vue?vue&type=template&id=228871e0&scoped=true&"
import script from "./MoveFilesToCategoryModal.vue?vue&type=script&lang=js&"
export * from "./MoveFilesToCategoryModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228871e0",
  null
  
)

export default component.exports